import React from 'react';
import tw from 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Text from '~components/Text';

const roundedImg = tw`rounded-tr-4xl`;

const FoundationsPage: React.FC = () => (
  <Layout>
    <SEO title="Strong Foundations" desc="" pathname="/foundations" />
    <Hero title="Strong Foundations">
      <StaticImage src="../images/staff-teaching.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container">
      <figure tw="my-12 flex flex-col justify-between space-y-4">
        <div tw="relative">
          <StaticImage
            style={roundedImg}
            src="../images/mohawk-building-larger.jpg"
            alt=""
            layout="fullWidth"
          />
          <div tw="bg-green text-gray-800 p-6 max-w-3xl lg:(absolute left-6 bottom-0 rounded-tr-4xl) ">
            <Text tw="font-bold">
              People | Planning | Reputation | Technology | Financial Health
            </Text>
            <Text>
              Strong Foundations underpin our new Strategic Plan to ensure that we can progress
              toward our aspirations and ultimately achieve our leadership outcomes. We will build
              on the expertise of our faculty and staff, maintain ongoing planning for long-term
              success, enhance and leverage our positive reputation, and remain focused on
              innovative technology. Above all, we will continue to pay diligent attention to our
              overall financial health.
            </Text>
          </div>
        </div>
        <div tw="block w-full h-6 shadow bg-orange" />
      </figure>
    </div>
  </Layout>
);

export default FoundationsPage;
